import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { FC, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';

import { FilledButton } from '@/components/core/Button/variants/FilledButton';
import { CloseButton as CloseBtn } from '@/components/core/CloseButton';
import { H2 } from '@/components/core/Heading';
import { ErrorMessage } from '@/components/core/inputs/ErrorMessage';
import { Field } from '@/components/core/inputs/Field';
import { FadeInModalContext } from '@/components/layouts/fadeInModalContext';
import { Overlay } from '@/components/Overlay';
import { FontFamily, OverlayType } from '@/enums/index';
import { useStores } from '@/hooks/useStores';
import { ButtonTheme } from '@/styles/themes/button';
import { BREAKPOINTS } from '@/styles/themes/default';
import { IThemeProps } from '@/styles/themes/types';
import { EMAIL_REGEX } from '@/utils/constants';

import { CheckYourEmail } from './CheckYourEmail';

const FieldContainer = styled('div')`
  margin-bottom: 24px;
  margin-top: 16px;
`;

const CloseButton = styled(CloseBtn)<IThemeProps>`
  margin-left: calc(100vw - 120px);

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    margin-top: 24px;
    margin-bottom: 64px;
    margin-left: calc(100vw - 176px);
  }
`;

const PasswordResetForm = styled.form<IThemeProps>`
  margin: 0 auto;
  justify-content: center;
  max-width: 390px;
  width: 100%;
  padding-top: 72px;
  padding-bottom: 120px;

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    max-height: 420px;
    padding: 0;
  }
`;

const PasswordResetTitle = styled(H2)<IThemeProps>`
  margin: 0 0 24px;
  color: white;

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    margin: 0 0 32px;
  }
`;

const SubmitButton = styled(FilledButton)<IThemeProps>`
  background: #b6ffe2;
  justify-content: center;
  width: 100%;

  &:hover {
    background: #b6ffe2;
  }

  &:active {
    background-color: #94beb7;
  }

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    margin-top: 34px;
    margin-bottom: 0;
    width: 158px;
  }
`;

const Paragraph = styled.p<IThemeProps>`
  color: white;
  margin: 24px 0 40px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.legacy.typography.fontFamily[FontFamily.IBMPlexSans]};

  @media (min-width: ${BREAKPOINTS.Desktop.min}px) {
    text-align: left;
    margin: 32px 0 55px;
  }
`;

interface IFormData {
  email: string;
}

export const PasswordReset: FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [userEmail, setUserEmail] = useState('');
  const { store } = useStores();
  const context = useContext(FadeInModalContext);
  const router = useRouter();

  const onCloseFlow = () => {
    context.showModalType.set(OverlayType.NONE);
  };

  const onSubmit = async ({ email }: IFormData) => {
    try {
      const body = {
        data: {
          type: 'password',
          attributes: {
            email,
            redirect_url: router.query.redirectUrl || '/',
          },
        },
      };

      await store.request('password', 'POST', body);
      setUserEmail(email);
    } catch (res) {
      console.log(res);
    }
  };

  return (
    <Overlay
      css={{
        padding: 40,
        background: `linear-gradient(0deg, rgba(0, 255, 224, 0.05), rgba(0, 255, 224, 0.05)), rgba(0, 0, 0, 0.5)`,
        backdropFilter: 'blur(75px)',
      }}
    >
      <CloseButton onClose={onCloseFlow} size="large" />

      {userEmail ? (
        <CheckYourEmail onCloseFlow={onCloseFlow} email={userEmail} />
      ) : (
        <PasswordResetForm onSubmit={handleSubmit(onSubmit)}>
          <PasswordResetTitle>Reset password</PasswordResetTitle>
          <Paragraph>
            Enter the email address associated with your account, and we’ll email you a link where you can create your
            new password.
          </Paragraph>
          <FieldContainer css={{ height: 111 }}>
            <Field
              {...register('email', {
                required: 'Field is required',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Email not valid',
                },
              })}
              label="Your email"
              variant="white"
              hasValue={Boolean(watch('email'))}
              isError={Boolean(errors['email'])}
            />
            <ErrorMessage errors={errors} name="email" />
          </FieldContainer>
          <SubmitButton buttonTheme={ButtonTheme.DARK}>send reset link</SubmitButton>
        </PasswordResetForm>
      )}
    </Overlay>
  );
};
